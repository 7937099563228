import Vue from 'vue'

import {
  Pagination, // 分页
  Dialog, // 对话框
  Dropdown, // 下拉菜单
  DropdownMenu, // 主菜单
  DropdownItem, // 子菜单
  Menu, // 菜单
  Submenu, // 菜单
  MenuItem, // 菜单
  MenuItemGroup, // 菜单
  Input,
  Radio, // 单选
  RadioGroup, // 单选组
  RadioButton, // 单选按钮
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  TimeSelect,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Alert,
  Icon,
  Row,
  Col,
  Upload,
  Spinner,
  Card,
  Step,
  Carousel, // 走马灯
  CarouselItem, // 走马灯
  Collapse, // 折叠面板
  CollapseItem, // 折叠面板
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Link,
  Divider, // 分割线
  Image, // 图像
  Loading,
  MessageBox,
  Message,
  Notification,
  Popconfirm, // 确认气泡
  Drawer, // 抽屉
  Descriptions, // 描述
  DescriptionsItem, // 描述
  // CascaderPanel,//级联选择器
  // Breadcrumb,
  // BreadcrumbItem,
  // Autocomplete,
  // InputNumber,
  // DatePicker,
  // TimePicker,
  // Popover,
  // Tooltip,
  // Tree,
  // Slider,
  // Progress,
  // Badge,
  // Rate,
  // Steps,
  // Cascader,
  // ColorPicker,
  // Transfer,
  // Timeline,
  // TimelineItem,
  // Calendar,
  // Backtop,
  PageHeader// 页头
} from 'element-ui'
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(TimeSelect)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Alert)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Spinner)
Vue.use(Card)
Vue.use(Step)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Link)
Vue.use(Divider)
Vue.use(Image)
Vue.use(Popconfirm)
Vue.use(Drawer)// 抽屉
Vue.use(Descriptions)// 描述
Vue.use(DescriptionsItem)// 描述
// Vue.use(CascaderPanel);
// Vue.use(Breadcrumb);
// Vue.use(BreadcrumbItem);
// Vue.use(Autocomplete);
// Vue.use(InputNumber);
// Vue.use(DatePicker);
// Vue.use(TimePicker);
// Vue.use(Popover);
// Vue.use(Tooltip);
// Vue.use(Tree);
// Vue.use(Slider);
// Vue.use(Progress);
// Vue.use(Badge);
// Vue.use(Rate);
// Vue.use(Steps);
// Vue.use(Cascader);
// Vue.use(ColorPicker);
// Vue.use(Transfer);
// Vue.use(Timeline);
// Vue.use(TimelineItem);
// Vue.use(Calendar);
// Vue.use(Backtop);
Vue.use(PageHeader)// 页头

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
