import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
// const indexMain = () => import('@/views/indexMain')
const appVip = () => import('@/views/appVip')
const indexHome = () => import('@/views/indexHome')
const errOff = () => import('@/views/err/errOff')
const errNull = () => import('@/views/err/errNull')
const docFile = () => import('@/views/files/docFile')
/// /////////////////////////////////////////////////////
const listInfo = () => import('@/views/info/listInfo')
const showInfo = () => import('@/views/info/showInfo')
const telInfo = () => import('@/views/info/telInfo')
/// /////////////////////////////////////////////////////
const listPro = () => import('@/views/pro/listPro')
/// /////////////////////////////////////////////////////
const userLogin = () => import('@/views/member/userLogin')
const userReg = () => import('@/views/member/userReg')
const userPass = () => import('@/views/member/userPass')
const userCenter = () => import('@/views/member/userCenter')
const userInfos = () => import('@/views/member/info/userInfos')
const userPay = () => import('@/views/member/pay/userPay')
const userModify = () => import('@/views/member/userModify')
/// /////////////////////////////////////////////////////
const adminCenter = () => import('@/views/admin/adminCenter')
const adminLogin = () => import('@/views/admin/adminLogin')
const adminInfos = () => import('@/views/admin/adminInfos')
const adminMember = () => import('@/views/admin/adminMember')
const adminMoney = () => import('@/views/admin/adminMoney')
/// /////////////////////////////////////////////////////
const testYanxuan = () => import('@/views/testYanxuan')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: indexHome
  }, {
    path: '/erroff',
    component: errOff
  }, {
    path: '/err404',
    component: errNull
  }, {
    path: '/test',
    component: testYanxuan
  }, {
    path: '/listinfo',
    component: listInfo
  }, {
    path: '/listinfo/:id',
    component: listInfo
  }, {
    path: '/listinfo/:id/:page',
    component: listInfo
  }, {
    path: '/showinfo/:id',
    component: showInfo
  }, {
    path: '/docfile/:id',
    component: docFile
  }, {
    path: '/vip',
    component: appVip
  }, {
    path: '/tel',
    component: telInfo
  }, {
    path: '/adminlogin',
    component: adminLogin
  }, {
    path: '/admin',
    component: adminCenter,
    redirect: '/admininfos',
    children: [
      {
        path: '/admininfos',
        component: adminInfos
      }, {
        path: '/adminmember',
        component: adminMember
      }, {
        path: '/adminmoney',
        component: adminMoney
      }
    ]
  }, {
    path: '/usercenter',
    component: userCenter,
    redirect: '/userinfos',
    children: [
      {
        path: '/userinfos',
        component: userInfos
      }, {
        path: '/userpay',
        component: userPay
      }, {
        path: '/usermodify',
        component: userModify
      }
    ]
  },
  {
    path: '/login',
    component: userLogin
  }, {
    path: '/reg',
    component: userReg
  }, {
    path: '/pass',
    component: userPass
  }, {
    path: '/pros',
    component: listPro
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

// 解决同一路由加载两遍报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

// 路由导航卫士
router.beforeEach((to, from, next) => {
  // if (to.path == '/login') {
  //   return next()
  // }
  // const token = window.sessionStorage.getItem('token')
  // if (!token) {
  //   return next({ path: '/login' })
  // }
  // next()
  const statData = {
    url: to.path,
    domain: window.location.host
  }
  axios.post('//stat.hzfc.5n5.cn/vue', statData).then(res => {

  })

  next()
})
